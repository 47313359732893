import Project1Image from '../../assets/project1.jpg'
import Project2Image from '../../assets/project2.jpg'
import Project3Image from '../../assets/finance-manager-new.png'
import ImageNotAvailable from '../../assets/ImageNotAvailable.png'
import Project5Image from '../../assets/kamis-ui.png'
import KamisAppsImage from '../../assets/kamis-apps.png'

const data = [
    {
        id: 1, 
        category: "Full Stack", 
        image: Project1Image, 
        title: "Kamis Financial Management",
        technologies: ["Go", "React JS", "Postgres", "Docker"],
        desc: "My First attempt at a personal finance application. This was my first shot at using Go and React JS, and as such the project is below my normal quality. Even still, the application can perform complex loan calculations and allow users to see a dashboard containing a rough estimate of their monthly incomes and expenses",
        demo: "none",
        github: "none"
    },
    {
        id: 2,
        category: "backend", 
        image: Project2Image, 
        title: "Klogger",
        technologies: ["Go"],
        desc: "A Go logging utility package built to write logs to the console and manage log files",
        demo: "none",
        github: "https://github.com/jon-kamis/klogger"
    },
    {
        id: 3,
        category: "Full Stack",
        image: Project3Image,
        title: "Kamis Financial Management V2",
        technologies: ["Java", "Spring", "React JS", "Postgres", "Docker", "Nginx", "VPS", "Azure DevOps"],
        desc: "My second attempt at a personal finance application. This was a massive improvement over the first attempt and was done using Java Spring Boot rather than Go. The site is not yet completed and may be redone in another iteration.",
        github: "none",
        demo: "http://kamis-apps.com/kamis-financial-management-v2/",
    },
    {
        id: 4,
        category: "Full Stack",
        image: ImageNotAvailable,
        title: "Kamis Financial Mangement 3.0 (IN PROGRESS)",
        technologies: ["Java", "Spring", "React TS", "Postgres", "Docker"],
        desc: "My third attempt at Finance Manager which will replace javascript for typescript in the frontend. The application will also be built using both Java and Go microservices to optimize performance",
        github: "none",
        demo: "none",
    },
    {
        id: 5,
        category: "Frontend",
        image: Project5Image,
        title: "Kamis.dev",
        technologies: ["React TS", "Azure DevOps"],
        desc: <p>A website I created to host portfolio sites for myself and a few friends. The site will eventually host several of my personal projects as well. This site was built using React with Typescript.<br/><br/>Changes to this site are deployed using custom Azure Pipelines complete with a CI/CD lifecycle</p>,
        github: "none",
        view: "https://kamis.dev",
    },
    {
        id: 6,
        category: "backend",
        image: KamisAppsImage,
        title: "kamis-apps.com",
        technologies: ["Nginx", "Azure DevOps", "VPS"],
        desc: <p>I set up a VPS using Hostinger to host several of the other portfolio projects displayed on this page. I used a private Azure DevOps agent to build and deploy each project out to this VPS and then utilized Nginx to handle proxying between each of them.</p>,
        github: "none",
        view: "https://kamis-apps.com",
    }
]

export default data;
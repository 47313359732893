import { RiReactjsLine } from "react-icons/ri";
import {FaDatabase, FaServer} from "react-icons/fa";
import { AiFillAndroid, AiFillApi, AiFillBulb, AiFillStar } from "react-icons/ai";
import { RiMicrosoftFill } from "react-icons/ri";
import {MdAutoAwesome, MdCloud} from "react-icons/md";

const data = [
    {id: 1, icon: <FaServer/>, title: "Backend Development", desc: "Most of my professional experience is in backend software development and design. I am most comfortable with Java Spring Boot and Golang"},
    {id: 2, icon: <RiMicrosoftFill/>, title: "Azure DevOps", desc: "I have extensive experience working with Azure Devops and served as a DevOps Engineer for over four years"},
    {id: 3, icon: <RiReactjsLine/>, title: "Frontend Development", desc: "My frontend experience is with React using both JavaScript and TypeScript languages" },
    {id: 4, icon: <AiFillAndroid/>, title: "Mobile Development", desc: "I have limited mobile development experience for Android Applications"},
    {id: 5, icon: <AiFillApi/>, title: "Microservice Development", desc: "I have over four years of experience building, managing, and deploying microservice applications"},
    {id: 6, icon: <AiFillStar/>, title: "Containerization", desc: "I have over three years of experience containerizing existing legacy applications and building new docker applications from scratch"},
    {id: 7, icon: <AiFillBulb/>, title: "Orchestration", desc: "I have configured and maintained RedHat Openshift, Kubernetes, and Docker Swarm Orchestration platforms to deploy enterprise applications."},
    {id: 8, icon: <MdAutoAwesome/>, title: "Event Driven Development", desc: "I have worked with Fabrik and Amazon Cloud Formation to implement event driven development to perform costly operations asynchronously."},
    {id: 9, icon: <MdCloud/>, title: "Cloud Engineering", desc: "I have worked with both AWS and Microsoft Azure Cloud platforms supporting application infrastructure"},
    {id: 10, icon: <FaDatabase/>, title: "Database Management", desc: "I have experience with Oracle and Postgres databases. I also am experienced with AWS DMS and RDS, Flyway, and DynamoDB"}
]

export default data
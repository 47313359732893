import Card from '../../components/Card'

const Project = ({project}) => {

  return (
    <Card className="portfolio__project">
        <div className="portfolio__project-image">
            <img src={project.image} alt="Project"/>
        </div>
        <h4>{project.title}</h4>
        <small>
            <b>
                {
                    project.technologies.map((tech, index, arr) => {
                        if (index === arr.length - 1) {
                            return tech;
                        } else {
                            return tech + ', ';
                        }
                    })
                }
            </b>
        </small>
        <p>{project.desc}</p>
        <div className="portfolio__project-cta">
            {
                project.demo && project.demo !== "none" ? <a href={project.demo} className = 'btn sm primary' target="_blank" rel="noopener noreferrer">Demo</a> : ""
            }
            {
                project.github && project.github !== "none" ? <a href={project.github} className="btn sm primary" target="_blank" rel="noopener noreferrer">Github</a> : ""
            }
            {
                project.view && project.view !== "none" ? <a href={project.view} target="_blank" className="btn sm primary" rel="noopener noreferrer">View</a> : ""
            }
        </div>
    </Card>
  )
}

export default Project